import styled from "styled-components";
import { ThemeProviderProps } from "@src/theme";
import React from "react";
import InternalLink from "@src/components/elements/link";
import kebabCase from "lodash/kebabCase";

const Link = styled(InternalLink)`
  color: ${(props: ThemeProviderProps) => props.theme.color.Comment};
  background-color: ${(props: ThemeProviderProps) =>
    props.theme.color.Background};
  border: 1px solid
    ${(props: ThemeProviderProps) => props.theme.color.CurrentLine};
  text-decoration: none;
  font-family: ${(props: ThemeProviderProps) => props.theme.fonts.Monospace};
  padding: 0.2em 0.8em;
  margin: 0 0.2rem 0 0;
  font-size: 0.8em;
  border-radius: 3px;
  text-transform: uppercase;

  &:hover {
    color: ${(props: ThemeProviderProps) => props.theme.color.Foreground};
    border: 1px solid
      ${(props: ThemeProviderProps) => props.theme.color.Selection};

    text-decoration: underline;
  }
`;

const TagLink = ({ name, to, ...props }) => (
  <Link to={to} className={props.className}>
    {name}
  </Link>
);

const Tag = ({ name, key = undefined }) => (
  <TagLink key={key} name={kebabCase(name)} to={`/tag/${kebabCase(name)}`} />
);
const Series = ({ name }) => (
  <TagLink name={kebabCase(name)} to={`/series/${kebabCase(name)}`} />
);

const TagSpan = styled.span`
  color: #999999;
  background-color: #2d2d2d;
  border: 1px solid #393939;
  font-family: "IBM Plex Mono", "SF Mono", Menlo, Monaco, "Courier New",
    monospace;
  padding: 0.2em 0.8em;
  margin: 0 0.2rem 0 0;
  font-size: 0.8em;
  border-radius: 3px;
  text-transform: uppercase;
  @media (max-width: 700px) {
    font-size: 0.6em;
  }
`;

const TagWithoutLink = ({ name }) => <TagSpan>{kebabCase(name)}</TagSpan>;
const SeriesWithoutLink = ({ name }) => <TagSpan>{kebabCase(name)}</TagSpan>;

export default TagLink;

export { Tag, Series, TagWithoutLink, SeriesWithoutLink };
